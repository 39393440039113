import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import React from "react";
import ImageStaticMap from "../Media/ImageStaticMap";

const CourtsList = ({ district, state }) => {
  if (district == null && state == null) return null;
  return (
    <StaticQuery
      query={graphql`
        query CourtsList {
          upsolve {
            allCourt {
              name
              phoneNumber
              url
              addresses {
                street1
                street2
                city
                county
                state
                zipcode
                latitude
                longitude
              }
              district {
                abbrev
                name
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <div>
          {data.upsolve.allCourt
            .filter((c) => {
              if (c.addresses && c.addresses[0]) return c.addresses[0].state.match(new RegExp(state, "i"));
              if (c.district && c.district.abbrev) return c.district.abbrev.match(new RegExp(district, "i"));
              return false;
            })
            .map((c) => (
              <CourtListItem key={c.name}>
                <ImageStaticMap alt={c.name} {...c.addresses?.[0]} height={120} width={120} zoom={11} />
                <p>
                  <b>
                    <a href={c.url}>{c.name}</a>
                  </b>
                  <br />
                  {c.phoneNumber}
                </p>
              </CourtListItem>
            ))}
        </div>
        )
      }}
    />
  );
};

const CourtListItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 1em;
  & > img {
    height: 120px;
    width: 120px;
    max-width: 120px;
    border-radius: 8px;
  }
  p {
    margin: 1em;
  }
`;

export default CourtsList;
